<template>
    <raw-content>
        This page is using empty layout set in routes + no HTML template
    </raw-content>
</template>

<script>
import { _st } from '@/softech';
import { PetuSubcription } from '@/classes/subscription';
import { PetuProduct } from '@/classes/product';
import { PetuCustomer } from '@/classes/customer';
import { PetuOrder } from '@/classes/order';

export default {
    props: {
        subscriptionId: { type: String, default: '' }
    },
    data() {
        return {

        }
    },
    mounted() {
        
    },
    methods: {

    },
    asyncData ({ store, route, context }) {
        contextserver.response.setHeader('Content-Type', 'text/json')
        context.output.template = ''
        return new Promise((resolve, reject) => {
            resolve()
        })
    },
}
</script>

<style lang="scss" scoped>

</style>